import Header from "./Components/header";
import { Sidebar } from "./Components/sidebar";
import React from "react";
import { Router } from "./Router";
import { useSelector } from "react-redux";
import { decryptData, getItem } from "./Services/Storage";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import { Axios } from "./Services/Axios";
import { CenterSettings } from "./Components/Settings/CenterSettings";
import { ToastContainer } from "react-toastify";
function App() {
  const auth = decryptData(useSelector((e) => e.LoggedIn));
  const role = decryptData(useSelector((e) => e.Role));
  React.useEffect(() => {
    if (auth) {
      const token = getItem("token");
      Axios.interceptors.request.use(
        async (config) => {
          config.headers = {
            ...config.headers,
            Authorization: `Bearer ${token}`,
          };
          return config;
        },
        (err) => Promise.reject(err)
      );
    }
  }, [auth]);
  // React.useEffect(() => {
  //   console.log(role, "l");
  // }, [role]);
  return (
    <section className="dashboardLayout w-full h-full flex">
      {auth ? (
        <>
          <Header />
          <Sidebar />
        </>
      ) : (
        <></>
      )}
      <div className="mainContainer">
        <Router />
        {role?.role === "center" ? <CenterSettings /> : <></>}
      </div>
      <ToastContainer
        position="bottom-right"
        theme="colored"
        autoClose={3000}
      />
    </section>
  );
}

export default App;

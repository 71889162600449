import React from "react";

function header() {
  return (
    <header className="top-header">
      <button className="btn btn-menu">
        <span></span>
        <span></span>
        <span></span>
      </button>
      <div className="logo">
        <h5 className="m-0">Aptech Dashboard</h5>
      </div>
      <div></div>
    </header>
  );
}

export default header;

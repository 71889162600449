import { useFormik } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { encryptData, setItem } from "../../Services/Storage";
import { Axios } from "../../Services/Axios/index";
import * as yup from "yup";

const validationSchema = yup.object({
  username: yup
    .string("Enter a valid value...")
    .required("This value is required..."),
  password: yup
    .string("Enter your password...")
    .required("Enter your password..."),
});

export const Login = () => {
  const [loading, setLoading] = React.useState(false);
  const [axiosError, setAxiosError] = React.useState(undefined);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema,
    onSubmit: (values) => {
      setLoading(true);
      Axios.post("/Users/login", values)
        .then((res) => {
          setLoading(false);
          setItem("token", res?.data?.token);
          setItem("user", res?.data?.users);
          setItem("role", { role: res?.data?.users?.role });
          if (res?.data?.users?.role === "center") {
            setItem("center", res?.data?.center);
            dispatch({
              type: "setCenter",
              payload: encryptData(res?.data?.center),
            });
          }
          dispatch({ type: "setLogin", payload: encryptData(true) });
          dispatch({ type: "setUser", payload: encryptData(res?.data?.users) });
          dispatch({
            type: "setRole",
            payload: encryptData({ role: res?.data?.users?.role }),
          });
          navigate("/");
        })
        .catch((err) => {
          setLoading(false);
          if (err?.response?.status) {
            setAxiosError(err?.response?.data);
          }
        });
    },
  });

  return (
    <>
      <section className="login_section">
        <div className="loginBody">
          <div className="login_form">
            <h1 className="m-0">Welcome back</h1>
            <p className="m-0">Welcome back! Please enter your details.</p>

            <form onSubmit={formik.handleSubmit}>
              <label>Email</label>
              <input
                type="text"
                id="username"
                name="username"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="inputform"
                placeholder="Enter your email"
                autoComplete="username"
              />
              <small className="text-danger">
                {formik.touched.username && formik.errors.username}
              </small>
              <label>Password</label>
              <input
                type="password"
                className="inputform"
                autoComplete="password"
                id="password"
                name="password"
                placeholder="Enter your password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <small className="text-danger">
                {formik.touched.password && formik.errors.password}
              </small>
              <small className="text-danger" style={{ marginTop: "15px" }}>
                {axiosError}
              </small>
              <button
                className="btn btn-theme"
                type="submit"
                disabled={!formik.isValid}
              >
                Sign in
              </button>
            </form>
          </div>
          <div className="loginFooter">
            <p>&copy; Aptech 2023</p>
          </div>
        </div>
        <div className="loginFigure">
          <img
            src="https://aptech-learning.netlify.app/assets/images/icon-aptech.png"
            alt=""
          />
          <div className="overlay"></div>
        </div>
      </section>
    </>
  );
};

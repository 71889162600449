import React, { useEffect, useState } from "react";
// import axios from 'axios';

import * as Yup from "yup";

import { toast } from "react-toastify";
import { Axios } from "../../Services/Axios";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { decryptData } from "../../Services/Storage";
import ReactPaginate from "react-paginate";
import ImportPlacements from "./Import";
import moment from "moment";

export const Placements = () => {
  // const role = decryptData(useSelector((e) => e.Role));
  const center = useSelector((e) => e.Center);
  const [formValues, setFormValues] = useState([]);
  const [centers, setCenters] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filtered, setFiltered] = React.useState([]);
  const [itemOffset, setItemOffset] = useState(0);
  const [img, setImg] = React.useState(null);
  const pageCount = Math.ceil(formValues.length / 10);
  const [selectedCenter, setSelectedCenter] = useState(null);
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = (currentDate.getMonth() + 1).toString().padStart(2, "0");
  const [selectedDate, setSelectedDate] = useState(
    `${currentYear}-${currentMonth}`
  );

  const handleDateChange = (event) => {
    let a = event.target.value;
    const [year, month] = a.split("-");
    setSelectedDate(a);
    GetPlacements(`${year}-${month}`);
  };

  // useEffect(() => {
  //   // Set the default value to the current month and year
  //   const currentDate = new Date();
  //   const currentYear = currentDate.getFullYear();
  //   const currentMonth = (currentDate.getMonth() + 1).toString().padStart(2, '0');
  //   setSelectedDate(`${currentYear}-${currentMonth}`);
  // }, []);
  const [open1, setOpen1] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    const endOffset = itemOffset + 10;
    console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    setFiltered(formValues.slice(itemOffset, endOffset));
  }, [itemOffset, formValues]);

  const handlePage = (event) => {
    const newOffset = (event.selected * 10) % formValues.length;
    setItemOffset(newOffset);
  };
  const initialValues = {
    studentName: "",
    company: "",
    designation: "",
    centresId: "",
    id: null,
    month: "",
  };

  const validationSchema = Yup.object().shape({
    studentName: Yup.string().required("This field is required..."),
    company: Yup.string().required("This field is required..."),
    designation: Yup.string().required("This field is required..."),
    centresId: Yup.string().required("This field is required..."),
    month: Yup.string().required("This field is required..."),
  });
  useEffect(() => {
    if (Boolean(center)) {
      formik.setFieldValue("centresId", decryptData(center)?.id);
      setSelectedCenter(decryptData(center)?.id);
    }
    GetPlacements(selectedDate);
  }, [center]);

  const GetPlacements = (date) => {
    setLoading(true);
    Axios.get(
      `/Placement/GetMonthWise?Id=${
        Boolean(center)
          ? decryptData(center)?.id
          : "00000000-0000-0000-0000-000000000000"
      }&date=${date}`
    ).then(
      (res) => {
        GetCenters();
        setFormValues(res.data);
      },
      () => {}
    );
  };

  const GetCenters = () => {
    Axios.get(`/Centres/GetAll`).then(
      (res) => {
        setLoading(false);
        setCenters(res.data);
      },
      () => {}
    );
  };

  const Edit = (r) => {
    formik.setValues(r);
    openModal();
  };
  const Delete = (id) => {
    Axios.delete(`/Placement/Remove?id=${id}`).then(
      () => {
        setFormValues((e) => [...e.filter((x) => x.id !== id)]);
        toast.success("Data Deleted Successfully...");
      },
      () => {
        toast.error("Something went wrong...");
      }
    );
  };

  const [open, setOpen] = useState(false);
  const openModal = () => {
    console.log(open);
    if (open) {
      formik.resetForm();
    }
    setOpen(!open);
  };

  const openModal1 = () => {
    setOpen1(!open1);
  };

  const handleSubmit = async (values, { resetForm }) => {
    const payload = new FormData();
    const keys = Object.keys(values);
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      if (values[key] && key !== "image") {
        payload.append(key, values[key]);
      }
    }
    if (img) {
      payload.append("image", img);
    }
    console.log(formik.values);
    if (formik.values.id) {
      Axios.put("/Placement/Update", payload, {
        headers: { "Content-Type": "multipart/form-data" },
      }).then(
        (res) => {
          setFormValues([
            ...formValues.filter((x) => x.id !== res.data.id),
            res.data,
          ]);
          resetForm();
          setOpen(!open);
          toast.success("Data Updated successfully");
        },
        (err) => {
          toast.error("Something went wrong...");
        }
      );
    } else {
      Axios.post("/Placement/Add", payload, {
        headers: { "Content-Type": "multipart/form-data" },
      }).then(
        (res) => {
          setFormValues([...formValues, res.data]);
          resetForm();
          setOpen(!open);
          toast.success("Data inserted successfully");
        },
        (err) => {
          toast.error("Something went wrong...");
        }
      );
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  return (
    <>
      <div className="pageTitle">
        <div>
          <h3>Placements</h3>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
          }}
        >
          <button
            type="button"
            onClick={() => {
              setOpen1(true);
            }}
            className="btn btntheme ml-5"
          >
            Import Placement
          </button>
          <button className="btn btntheme ml-5" onClick={openModal}>
            Add Placement
          </button>

          <div>
            <label htmlFor="monthYear">Month-Year:</label>
            <input
              type="month"
              id="monthYear"
              value={selectedDate}
              onChange={handleDateChange}
            />
          </div>
        </div>
      </div>
      <div className="table">
        <div className="flex tableheader">
          <div>Student Name</div>
          <div>Company</div>
          <div>Designation</div>
          <div>Center</div>
          <div>Action</div>
        </div>
        <div className="tablebody">
          {loading && <p>Loading...</p>}
          {!loading && formValues.length === 0 && <p>No data found.</p>}
          {!loading && formValues.length > 0 && (
            <>
              {filtered.map((e, i) => (
                <div className="flex" key={i}>
                  <div>
                    <span className="my-des-alt">{e.studentName}</span>
                  </div>
                  <div>
                    <span className="my-des-alt">{e.company}</span>
                  </div>
                  <div>
                    <span className="my-des-alt">{e.designation}</span>
                  </div>
                  <div>
                    <span className="my-des-alt">{e.centres}</span>
                  </div>
                  <div>
                    <span className="mx-2" onClick={() => Edit(e)}>
                      <i className="fa-solid fa-pen-to-square"></i>
                    </span>
                    <span className="mx-2" onClick={() => Delete(e.id)}>
                      <i className="fa-solid fa-trash-can"></i>
                    </span>
                  </div>
                </div>
              ))}
              <ReactPaginate
                breakLabel="..."
                nextLabel="&raquo;"
                onPageChange={handlePage}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel="&laquo;"
                renderOnZeroPageCount={null}
                containerClassName="mars-pagination"
                pageClassName="mars-page"
                nextClassName="mars-page"
                previousClassName="mars-page"
                activeClassName="active"
              />
            </>
          )}
        </div>
      </div>
      {open && (
        <div className="popup_overlay">
          <div className="popup_notify">
            <div className="popup_header">
              <h4>Add Placement</h4>
              <button className="btn" onClick={openModal}>
                <i className="fa-solid fa-xmark"></i>
              </button>
            </div>
            <div className="popup_body">
              <form onSubmit={formik.handleSubmit}>
                <label htmlFor="studentName">Student Name</label>
                <input
                  type="text"
                  id="studentName"
                  className="input"
                  name="studentName"
                  placeholder="eg. Faisal Khan"
                  value={formik.values.studentName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <small className="error">
                  {formik.touched.studentName && formik.errors.studentName}
                </small>

                <label htmlFor="company">Company</label>
                <input
                  type="text"
                  id="company"
                  className="input"
                  name="company"
                  placeholder="eg. Innovador Solutions"
                  value={formik.values.company}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <small className="error">
                  {formik.touched.company && formik.errors.company}
                </small>

                <label htmlFor="designation">Designation</label>
                <input
                  type="text"
                  id="designation"
                  className="input"
                  name="designation"
                  placeholder="eg. Software Engineer"
                  value={formik.values.designation}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <small className="error">
                  {formik.touched.designation && formik.errors.designation}
                </small>
                <label htmlFor="month">Month</label>
                <input
                  type="month"
                  id="month"
                  className="input"
                  name="month"
                  value={formik.values.month}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <small className="error">
                  {formik.touched.month && formik.errors.month}
                </small>

                <label htmlFor="centresId">Center</label>
                <select
                  name="centresId"
                  id="centresId"
                  className="input"
                  value={formik.values?.centresId}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled={Boolean(center)}
                >
                  <option value="" disabled>
                    Select Center
                  </option>
                  {centers.map((e) => (
                    <option value={e.id} key={e.id}>
                      {e.centreName}
                    </option>
                  ))}
                </select>
                <small className="error">
                  {formik.touched.centresId && formik.errors.centresId}
                </small>
                <label htmlFor="CentreVideo">Image</label>
                <input
                  type="file"
                  id="image"
                  className="input"
                  name="image"
                  accept="image/*"
                  onChange={(e) => {
                    setImg(e.target.files[0]);
                  }}
                />
                <div className="popup_footer">
                  <button
                    className="btn btnclose"
                    onClick={openModal}
                    type="button"
                  >
                    Close
                  </button>
                  <button type="submit" className="btn btntheme">
                    Save
                  </button>
                </div>
              </form>
            </div>
            {/* <div className="popup_footer">
                <button className="btn btnclose"  onClick={openModal}>Close</button>
                <button className="btn btntheme">Save</button>
            </div> */}
          </div>
        </div>
      )}

      {open1 && (
        <div className="popup_overlay">
          <div
            className="popup_notify"
            style={{ width: "100%", height: "100%" }}
          >
            <div className="popup_header">
              <h4>Add Placement</h4>
              <button className="btn" onClick={openModal1}>
                <i className="fa-solid fa-xmark"></i>
              </button>
            </div>
            <div className="popup_body">
              <ImportPlacements
                setOpen={setOpen1}
                open={open1}
                setData={setData}
                data={data}
                centerId={selectedCenter}
              />
            </div>
            {/* <div className="popup_footer">
                <button className="btn btnclose"  onClick={openModal}>Close</button>
                <button className="btn btntheme">Save</button>
            </div> */}
          </div>
        </div>
      )}
    </>
  );
};

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { decryptData } from "../../Services/Storage";
import "./style.css";
import { Axios } from "../../Services/Axios";

const Index = () => {
  const center = useSelector((e) => e.Center);
  const [isCheckOut, setIsCheckOut] = useState(false);
  const [selectedBooks, setSelectedBooks] = useState([]);
  const [fileName, setFileName] = useState("");
  const [centreName, setCentreName] = useState("");
  const [centreId, setCentreId] = useState("");
  const [remarks, setRemarks] = useState("");

  const [books, setBooks] = useState([]);

  const getBooks = () => {
    Axios.get(`/Book/GetAll`).then(
      (res) => {
        console.log(res.data);
        if (res.data.length > 0) {
          const payload = res.data.map((e, i) => {
            return {
              id: i + 1,
              bookname: e.bookName,
              price: e.unitPrice,
              quantity: "",
              semester: e.semester,
              checked: false,
              course: e.course,
              availableQuantity: 10,
            };
          });

          setBooks(payload);
        }
      },
      () => {}
    );
  };

  useEffect(() => {
    if (Boolean(center)) {
      setCentreName(decryptData(center)?.centreName);
      setCentreId(decryptData(center)?.id);
      console.log(decryptData(center)?.id);
    }

    getBooks();
  }, [center]);

  const handleCheckboxChange = (id) => {
    const updatedBooks = books.map((book) => {
      if (
        book.id === id &&
        book.quantity !== "" &&
        !isNaN(book.quantity) &&
        parseInt(book.quantity) !== 0
      ) {
        return { ...book, checked: !book.checked };
      }
      return book;
    });
    setBooks(updatedBooks);
  };

  const handleQuantityChange = (id, quantity) => {
    const updatedBooks = books.map((book) =>
      book.id === id ? { ...book, quantity: quantity } : book
    );
    setBooks(updatedBooks);
  };

  const handleProceed = () => {
    // Check if there are any books with invalid quantities
    const invalidQuantityBooks = books.filter(
      (book) =>
        book.checked && (book.quantity === '' || isNaN(book.quantity) || parseInt(book.quantity) <= 0 || parseInt(book.quantity) > book.availableQuantity)
    );
  
    // If there are any books with invalid quantities, display an alert message
    if (invalidQuantityBooks.length > 0) {
      alert("Invalid Quantity");
    } else {
      // Proceed with selecting the valid books
      const selectedBooks = books.filter((book) => book.checked);
      setSelectedBooks(selectedBooks);
      setIsCheckOut(true);
    }
  };
  

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFileName(file.name);
    } else {
      setFileName("");
    }
  };

  const handleCheckout = () => {
    const payload = selectedBooks.map((e) => {
      return {
        orderBy: centreName,
        orderTo: "Printers",
        orderQTY: e.quantity,
        semester: e.semester,
        bookname: e.bookname,
        remarks: remarks,
        currentStatus: "ordered",
        course: e.course,
        unitPrice: e.price,
        totalPrice: e.price * e.quantity,
        centresId:centreId
      };
    });

    console.log(payload);
  };

  if (isCheckOut) {
    const total = selectedBooks.reduce((acc, book) => {
      return acc + book.price * book.quantity;
    }, 0);

    return (
      <>
        <div className="mt-5">
          <button
            className="btn "
            onClick={() => {
              setIsCheckOut(false);
            }}
          >
            <i className="fa-solid fa-arrow-left" style={{ fontSize: 30 }}></i>
          </button>
        </div>

        <div className="checkout-container">
          <h1 className="checkout-heading">Checkout</h1>
          <div className="selected-books-container">
            <table className="selected-books-table">
              <thead>
                <tr>
                  <th>Book Name</th>
                  <th>Price</th>
                  <th>Quantity</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {selectedBooks.map((book) => (
                  <tr key={book.id}>
                    <td>{book.bookname}</td>
                    <td>{book.price}</td>
                    <td>{book.quantity}</td>
                    <td>{book.price * book.quantity}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="con-abc">
            <div className="col-6">
              <div className="file-input-container">
                <input
                  type="file"
                  className="file-input"
                  id="file"
                  onChange={handleFileChange}
                />
                <label htmlFor="file" className="file-label">
                  {fileName ? fileName : "Payment Slip"}
                </label>
              </div>
            </div>
            <div className="col-7">
              <textarea
                name="Remarks"
                id=""
                className="remarks"
                placeholder="Remarks"
                onChange={(e) => setRemarks(e.target.value)}
              ></textarea>
            </div>
          </div>
          <div className="total-section">
            <h2 className="total-text">Total: {total}</h2>
            <button className="custom-button" onClick={handleCheckout}>
              Check Out
            </button>
          </div>
        </div>
      </>
    );
  }

  return (
    <div className="container">
      <table className="custom-table">
        <thead>
          <tr>
            <th></th>
            <th>ID</th>
            <th>Course</th>
            <th>Semester</th>
            <th>Book Name</th>
            <th>Price</th>
            <th>Stock</th>
            <th>Quantity</th>
          </tr>
        </thead>
        <tbody>
          {books.map((book) => (
            <tr key={book.id}>
              <td>
                <input
                  className="custom-checkbox"
                  type="checkbox"
                  checked={book.checked}
                  onChange={() => handleCheckboxChange(book.id)}
                  disabled={
                    book.quantity === "" ||
                    isNaN(book.quantity) ||
                    parseInt(book.quantity) === 0
                  }
                />
              </td>
              <td>{book.id}</td>
              <td>{book.course}</td>
              <td>{book.semester}</td>
              <td>{book.bookname}</td>
              <td>{book.price}</td>
              <td>{10}</td>
              <td>
                <input
                  className="custom-input"
                  type="number"
                  min="1"
                  max={book.availableQuantity}
                  value={book.quantity}
                  onChange={(e) =>
                    handleQuantityChange(book.id, e.target.value)
                  }
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <button className="custom-button" onClick={handleProceed}  >
        Proceed
      </button>
    </div>
  );
};

export default Index;

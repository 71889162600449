import { combineReducers } from "redux";
import { Center, LoggedIn, OpenSettings, Role, User } from "./Reducers";

export const rootReducer = combineReducers({
  LoggedIn,
  Role,
  User,
  OpenSettings: OpenSettings,
  Center: Center,
});

import axios from "axios";
export const Axios = axios.create({
  // baseURL: "http://localhost:5000/api/",
  // baseURL: "https://jnvj91m4-7290.asse.devtunnels.ms/api/",
  // baseURL: "https://webbackend.aptech-education.com.pk/api/",
  baseURL: "https://webbackend.aptech-education.com.pk/api/",
  headers: {
    "Content-Type": "application/json",
  },
});

// export const contentUrl = "http://localhost:5000/";
// export const contentUrl = "https://hjdt5pq5-7290.inc1.devtunnels.ms/";
export const contentUrl = "https://webbackend.aptech-education.com.pk/";


import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { Axios } from "../../Services/Axios";
import { useFormik } from "formik";
import ReactPaginate from "react-paginate";

const Index = () => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState([]);
  const pageCount = Math.ceil(formValues.length / 10);
  const [itemOffset, setItemOffset] = useState(0);
  const [filtered, setFiltered] = React.useState([]);

  const getCourses = () => {
    Axios.get(`/Course/GetAllDeleted`).then((res) => {
      setLoading(false);
      setFormValues(res.data);
    });
  };

  useState(() => {
    getCourses();
  }, []);

  useEffect(() => {
    const endOffset = itemOffset + 10;
    console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    setFiltered(formValues.slice(itemOffset, endOffset));
  }, [itemOffset, formValues]);

  const handlePage = (event) => {
    const newOffset = (event.selected * 10) % formValues.length;
    setItemOffset(newOffset);
  };

  const initialValues = {
    id: null,
    name: "",
    description: "",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("This field is required..."),
  });
  const openModal = () => {
    if (open) {
      formik.resetForm();
    }
    setOpen(!open);
  };

  const handleSubmit = async (values, { resetForm }) => {
    const payload = new FormData();
    const keys = Object.keys(values);
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      payload.append(key, values[key]);
    }
    if (values.id) {
      Axios.put("/Course/Update", payload, {
        headers: { "Content-Type": "multipart/form-data" },
      }).then(
        (res) => {
          resetForm();
          setOpen(!open);
          getCourses();
          toast.success("Data Updated successfully");
        },
        (err) => {
          toast.error("Something went wrong...");
        }
      );
    } else {
      payload.delete("id");
      Axios.post("/Course/Add", payload, {
        headers: { "Content-Type": "multipart/form-data" },
      }).then(
        (res) => {
          resetForm();
          setOpen(!open);
          getCourses();
          toast.success("Data Updated successfully");
        },
        (err) => {
          toast.error("Something went wrong...");
        }
      );
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  const handleDelete = (id) => {
    Axios.delete(`/Course/Remove?id=${id}`).then(
      (res) => {
        getCourses();
        toast.success("Data Delete successfully");
      },
      (err) => {
        toast.error("Something went wrong...");
      }
    );
  };

  const handleEdit = (obj) => {
    setOpen(!open);
    formik.setValues(obj);
  };

  const handleCheck = (id) => {
    console.log(id);
    Axios.put(`/Course/ActiveInActive?Id=${id}`).then((res) => {
      getCourses();
    });
  };

  return (
    <>
      <div className="pageTitle">
        <div>
          <h3>Courses</h3>
        </div>
        <button className="btn btntheme" onClick={openModal}>
          Add Course
        </button>
      </div>

      <table id="customers">
        <tr>
          <th>Course Name</th>
          <th>Description</th>
          <th>Action</th>
        </tr>
        {loading && <p>Loading...</p>}
        {!loading && formValues.length === 0 && <p>No data found.</p>}
        {!loading && formValues.length > 0 && (
          <>
            {filtered.map((e, i) => (
              <tr>
                <td>{e.name}</td>
                <td>{e.description}</td>
                <td>
                  <label class="toggle-switch">
                    <input
                      type="checkbox"
                      checked={e?.active === "Y" ? "checked" : ""}
                      onChange={() => {
                        handleCheck(e?.id);
                      }}
                    />
                    <span class="toggle-slider"></span>
                  </label>

                  <span onClick={() => handleEdit(e)} className="ml-5">
                    <i class="fa fa-pencil" aria-hidden="true"></i>
                  </span>
                  <span onClick={() => handleDelete(e.id)} className="ml-5">
                    <i class="fa fa-trash" aria-hidden="true"></i>
                  </span>
                </td>
              </tr>
            ))}
          </>
        )}
      </table>
      {!loading && formValues.length > 0 && (
        <div>
          <ReactPaginate
            breakLabel="..."
            nextLabel="&raquo;"
            onPageChange={handlePage}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="&laquo;"
            renderOnZeroPageCount={null}
            containerClassName="mars-pagination"
            pageClassName="mars-page"
            nextClassName="mars-page"
            previousClassName="mars-page"
            activeClassName="active"
          />
        </div>
      )}

      {open && (
        <div className="popup_overlay">
          <div className="popup_notify">
            <div className="popup_header">
              <h4>Add Course</h4>
              <button className="btn" onClick={openModal}>
                <i className="fa-solid fa-xmark"></i>
              </button>
            </div>
            <div className="popup_body">
              <form onSubmit={formik.handleSubmit}>
                <label htmlFor="name">Course Name</label>
                <input
                  type="text"
                  id="name"
                  className="input"
                  name="name"
                  placeholder="eg. ACCP"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <small className="error">
                  {formik.touched.name && formik.errors.name}
                </small>

                <label htmlFor="description">Description</label>
                <input
                  type="text"
                  id="description"
                  className="input"
                  name="description"
                  placeholder="Optional"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <small className="error">
                  {formik.touched.description && formik.errors.description}
                </small>
                <div className="popup_footer">
                  <button
                    className="btn btnclose"
                    onClick={openModal}
                    type="button"
                  >
                    Close
                  </button>
                  <button type="submit" className="btn btntheme">
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Index;

import React from "react";
import { Importer, ImporterField } from "react-csv-importer";
import "react-csv-importer/dist/index.css";
import moment from "moment";
import { Axios } from "../../Services/Axios";

const ImportPlacements = ({ open, setOpen, setData, centerId }) => {
  const ConvertDates = (dateString) => {
    const formats = [
      "YYYY-MM-DD",
      "M/D/YYYY",
      "MM/DD/YYYY",
      "YYYYMMDD",
      "MM-DD-YYYY",
      "M-D-YYYY",
      "DD-MM-YYYY",
      "DD/MM/YYYY",
    ];

    for (const format of formats) {
      const parsedDate = moment.utc(dateString, format, true);

      if (parsedDate.isValid()) {
        // If parsing is successful, return the date in "YYYY-MM-DD" format
        const formattedDate = parsedDate.format("YYYY-MM-DD");

        // Return the ISO string of the formatted date
        return moment.utc(formattedDate).toISOString().replace(/[zZ]/g, "");
      }
    }

    throw new Error(`Unable to parse the date string: ${dateString}`);
  };
  return (
    <div className="mt-5 ml-5 mr-5">
      <Importer
        defaultNoHeader={false}
        restartable={false}
        dataHandler={async (rows) => {
          if (centerId) {
            rows.forEach((obj) => {
              Object.assign(obj, {
                centresId: centerId,
              });
            });

            console.log(rows, "ROW");
            await Axios.post("/Placement/AddRange", rows);
          } else {
            alert("Please select center");
          }
        }}
        onComplete={({ file, preview, fields, columnFields }) => {
          setOpen(false);
        }}
      >
        <ImporterField name="studentName" label="Student Name" />
        <ImporterField name="company" label="Company" />
        <ImporterField name="designation" label="Designation" />
        <ImporterField name="month" label="Month" />
        <ImporterField name="image" label="Image URL" />
      </Importer>
    </div>
  );
};

export default ImportPlacements;

import { encryptData, getEItem, token } from "../../Storage";

const loggedIn = encryptData(Boolean(token()));
const role = getEItem("role") || null;
const user = getEItem("user") || null;
const center = getEItem("center") || null;
const openSettings = encryptData(false);

export const LoggedIn = (state = loggedIn, action) => {
  switch (action.type) {
    case "setLogin":
      return (state = action.payload);
    default:
      return state;
  }
};
export const Role = (state = role, action) => {
  switch (action.type) {
    case "setRole":
      return (state = action.payload);
    default:
      return state;
  }
};

export const User = (state = user, action) => {
  switch (action.type) {
    case "setUser":
      return (state = action.payload);
    default:
      return state;
  }
};
export const Center = (state = center, action) => {
  switch (action.type) {
    case "setCenter":
      return (state = action.payload);
    default:
      return state;
  }
};
export const OpenSettings = (state = openSettings, action) => {
  switch (action.type) {
    case "setOpenSettings":
      return (state = action.payload);
    default:
      return state;
  }
};

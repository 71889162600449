import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, input, ErrorMessage, useFormik } from "formik";
import { decryptData, encryptData, setItem } from "../../Services/Storage";
import * as Yup from "yup";
import { Axios } from "../../Services/Axios";
import { toast } from "react-toastify";

export const CenterSettings = () => {
  const [centerVideo, setCenterVideo] = React.useState(null);
  const [img, setImg] = React.useState(null);
  const open = decryptData(useSelector((e) => e.OpenSettings));
  const center = decryptData(useSelector((e) => e.Center));
  const [cities, setCities] = React.useState([]);
  const dispatch = useDispatch();
  const [initialValues, setInitialValues] = React.useState(null);

  React.useEffect(() => {
    if (open) {
      Axios.get(`/Centres/GetById?id=${center?.id}`).then(
        (res) => {
          GetAllCities();
          setInitialValues(res.data);
        },
        () => {}
      );
    }
  }, [open]);

  const GetAllCities = () => {
    Axios.get("/City/GetAll").then(
      (res) => {
        setCities(res.data);
      },
      () => {}
    );
  };

  const openModal = () => {
    dispatch({ type: "setOpenSettings", payload: encryptData(!open) });
  };
  const validationSchema = Yup.object().shape({
    centreName: Yup.string().required("Center Name is required"),
    centreCode: Yup.string().required("Center Code is required"),
    phne: Yup.string().required("Phone is required"),
    centreEmail: Yup.string()
      .email("Invalid email")
      .required("Email is required"),
    centreAddress: Yup.string().required("Address is required"),
    maapLink: Yup.string().required("Map URL is required"),
    phne2: Yup.string().required("Phone 2 is required"),
    cityId: Yup.string().required("City is required"),
    fbLink: Yup.string().required("Facebook Link is required"),
    twLink: Yup.string().required("Twitter Link is required"),
    inLink: Yup.string().required("LinkedIn Link is required"),
    ytLink: Yup.string().required("Youtube Link is required"),
  });
  const handleSubmit = async (values, { resetForm }) => {
    const payload = new FormData();
    const keys = Object.keys(values);
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      if (key !== "status" && key !== "centreVideo" && key !== "image") {
        payload.append(key, values[key]);
      }
    }
    if (centerVideo) {
      payload.append("centreVideo", centerVideo);
    }
    if (img) {
      payload.append("image", img);
    }
    Axios.put("/Centres/Update", payload, {
      headers: { "Content-Type": "multipart/form-data" },
    }).then(
      (res) => {
        setItem("center", res?.data);
        dispatch({
          type: "setCenter",
          payload: encryptData(res?.data),
        });
        resetForm();
        openModal();
        toast.success("Data inserted successfully");
      },
      (err) => {
        toast.error("Something went wrong...");
      }
    );
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  return (
    <>
      {open && (
        <div className="popup_overlay">
          <div className="popup_notify">
            <div className="popup_header">
              <h4>Center Settings</h4>
              <button className="btn" onClick={openModal}>
                <i className="fa-solid fa-xmark"></i>
              </button>
            </div>
            <div className="popup_body">
              {initialValues ? (
                <>
                  <form onSubmit={formik.handleSubmit}>
                    <label htmlFor="centreName">Center Name</label>
                    <input
                      type="text"
                      id="centreName"
                      className="input"
                      name="centreName"
                      placeholder="eg. Aptech Metro Star Gate"
                      disabled={true}
                      defaultValue={formik.values?.centreName}
                    />
                    <small className="error">
                      {formik.touched.centreName && formik.errors.centreName}
                    </small>
                    <label htmlFor="centreCode">Center Code</label>
                    <input
                      type="text"
                      id="centreCode"
                      className="input"
                      name="centreCode"
                      placeholder="eg. MSG"
                      disabled={true}
                      defaultValue={formik.values?.centreCode}
                    />
                    <small className="error">
                      {formik.touched.centreCode && formik.errors.centreCode}
                    </small>
                    <label htmlFor="centreEmail">Email</label>
                    <input
                      type="text"
                      id="centreEmail"
                      className="input"
                      name="centreEmail"
                      placeholder="eg. email@example.com"
                      disabled={true}
                      defaultValue={formik.values?.centreEmail}
                    />
                    <small className="error">
                      {formik.touched.centreEmail && formik.errors.centreEmail}
                    </small>
                    <label htmlFor="phne">Phone</label>
                    <input
                      type="text"
                      id="phne"
                      className="input"
                      name="phne"
                      placeholder="eg. +9231126664496"
                      value={formik.values?.phne}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <small className="error">
                      {formik.touched.phne && formik.errors.phne}
                    </small>

                    <label htmlFor="centreAddress">Address</label>
                    <input
                      type="text"
                      id="centreAddress"
                      className="input"
                      name="centreAddress"
                      placeholder="eg. Shah Faisal"
                      value={formik.values?.centreAddress}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <small className="error">
                      {formik.touched.centreAddress &&
                        formik.errors.centreAddress}
                    </small>

                    <label htmlFor="centreCity">City</label>
                    <select
                      name="cityId"
                      id="cityId"
                      className="input"
                      defaultValue={formik.values?.cityId}
                      disabled
                    >
                      <option value="" disabled>
                        Select Value
                      </option>
                      {cities.map((e) => (
                        <option value={e.id} key={e.id}>
                          {e.name}
                        </option>
                      ))}
                    </select>
                    <small className="error">
                      {formik.touched.cityId && formik.errors.cityId}
                    </small>

                    <label htmlFor="maapLink">Map URL</label>
                    <input
                      type="text"
                      id="maapLink"
                      className="input"
                      name="maapLink"
                      placeholder="eg. "
                      value={formik.values?.maapLink}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <small className="error">
                      {formik.touched.maapLink && formik.errors.maapLink}
                    </small>
                    <label htmlFor="phne2">Phone 2</label>
                    <input
                      type="text"
                      id="phne2"
                      className="input"
                      name="phne2"
                      placeholder="eg. +9231126664496"
                      value={formik.values?.phne2}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <small className="error">
                      {formik.touched.phne2 && formik.errors.phne2}
                    </small>

                    <label htmlFor="fbLink">Facebook URL</label>
                    <input
                      type="text"
                      id="fbLink"
                      className="input"
                      name="fbLink"
                      placeholder="eg. "
                      value={formik.values?.fbLink}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <small className="error">
                      {formik.touched.fbLink && formik.errors.fbLink}
                    </small>
                    <label htmlFor="twLink">Twitter URL</label>
                    <input
                      type="text"
                      id="twLink"
                      className="input"
                      name="twLink"
                      placeholder="eg. "
                      value={formik.values?.twLink}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <small className="error">
                      {formik.touched.twLink && formik.errors.twLink}
                    </small>
                    <label htmlFor="inLink">LinkedIn URL</label>
                    <input
                      type="text"
                      id="inLink"
                      className="input"
                      name="inLink"
                      placeholder="eg. "
                      value={formik.values?.inLink}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <small className="error">
                      {formik.touched.inLink && formik.errors.inLink}
                    </small>
                    <label htmlFor="ytLink">Youtube URL</label>
                    <input
                      type="text"
                      id="ytLink"
                      name="ytLink"
                      className="input"
                      placeholder="eg. "
                      value={formik.values?.ytLink}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <small className="error">
                      {formik.touched.ytLink && formik.errors.ytLink}
                    </small>
                    <label htmlFor="CentreVideo">Center Video</label>
                    <input
                      type="file"
                      id="CentreVideo"
                      className="input"
                      name="CentreVideo"
                      accept="video/*"
                      onChange={(e) => {
                        setCenterVideo(e.target.files[0]);
                      }}
                    />
                    <label htmlFor="CentreVideo">Center Image</label>
                    <input
                      type="file"
                      id="image"
                      className="input"
                      name="image"
                      accept="image/*"
                      onChange={(e) => {
                        setImg(e.target.files[0]);
                      }}
                    />

                    <div className="popup_footer">
                      <button className="btn btnclose" onClick={openModal}>
                        Close
                      </button>
                      <button type="submit" className="btn btntheme">
                        Save
                      </button>
                    </div>
                  </form>
                </>
              ) : (
                <></>
              )}
            </div>
            {/* <div className="popup_footer">
                <button className="btn btnclose"  onClick={openModal}>Close</button>
                <button className="btn btntheme">Save</button>
            </div> */}
          </div>
        </div>
      )}
    </>
  );
};

import React, { useEffect, useState } from "react";
import { Axios, contentUrl } from "../../Services/Axios";
import ReactPaginate from "react-paginate";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { Calendar } from "react-date-range";

const Orders = () => {
  // Get current date
  const currentDate = new Date();
  // Format the current date to YYYY-MM format
  const currentMonth = `${currentDate.getFullYear()}-${(
    currentDate.getMonth() + 1
  )
    .toString()
    .padStart(2, "0")}`;

  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState([]);
  const pageCount = Math.ceil(formValues.length / 10);
  const [itemOffset, setItemOffset] = useState(0);
  const [filtered, setFiltered] = React.useState([]);
  const [statuses, setStatuses] = React.useState({});
  const [checkedRows, setCheckedRows] = React.useState([]);
  const [orderStatus, setOrderStatus] = useState("approved");

  const getOrders = () => {
    Axios.get(`/Order/GetByStatus?Status=approved`).then((res) => {
      setLoading(false);
      setFormValues(res.data);
    });
  };

  useState(() => {
    //getOrders();
  }, []);

  useEffect(() => {
    const endOffset = itemOffset + 10;
    console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    setFiltered(formValues.slice(itemOffset, endOffset));
  }, [itemOffset, formValues]);

  const handlePage = (event) => {
    const newOffset = (event.selected * 10) % formValues.length;
    setItemOffset(newOffset);
  };

  const handleChnage = (id, value) => {
    console.log(id);
    setStatuses((prevStatuses) => ({
      ...prevStatuses,
      [id]: value,
    }));

    Axios.put(`/Order/Update?Id=${id}&Status=${value}`)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCheckboxChange = (id) => {
    // Toggle the checked state of the row with the given ID
    setCheckedRows((prevCheckedRows) => {
      if (prevCheckedRows.includes(id)) {
        return prevCheckedRows.filter((rowId) => rowId !== id);
      } else {
        return [...prevCheckedRows, id];
      }
    });
  };

  const handleApprove = () => {
    // Send a request to update the status of checked rows to "approved"
    Axios.put(`/Order/UpdateRange?Status=printing`, checkedRows)
      .then((res) => {
        console.log(res);
        handleFilterMethod();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleReject = () => {
    // Send a request to update the status of checked rows to "approved"
    Axios.put(`/Order/UpdateRange?Status=printed`, checkedRows)
      .then((res) => {
        console.log(res);
        handleFilterMethod();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDispatch = () => {
    Axios.put(`/Order/UpdateRange?Status=disptach`, checkedRows)
      .then((res) => {
        console.log(res);
        getOrders();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleFilterChange = (e) => {
    setOrderStatus(e.target.value);
  };

  const handleFilterMethod = () => {
    Axios.get(
      `/Order/GetByStatus?Status=${orderStatus}&startDate=${selectedMonth}`
    ).then((res) => {
      setLoading(false);
      setFormValues(res.data);
    });
  };

  const [expandedRow, setExpandedRow] = useState(null);

  const toggleRow = (index) => {
    if (expandedRow === index) {
      setExpandedRow(null);
    } else {
      setExpandedRow(index);
    }
  };

  

  return (
    <>
      <div className="table mt-5">
        {checkedRows.length > 0 && (
          <>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <button
                className="btn btntheme ml-5 mb-5"
                onClick={handleApprove}
              >
                Printing
              </button>
              <button className="btn btntheme ml-5 mb-5" onClick={handleReject}>
                Printed
              </button>
              {/* <button className="btn btntheme ml-5 mb-5" onClick={handleDispatch}>
                Dispatch
              </button> */}
            </div>
          </>
        )}

<div className="mb-5" style={{ display: "flex" }}>
          <label htmlFor="filterSelect" class="filter-label">
            Filter by:
          </label>
          <select
            id="filterSelect"
            class="form-select"
            name="filter"
            onChange={handleFilterChange}
          >
            {/* <option value="ordered">Ordered</option> */}
            <option value="approved">Approved</option>
            {/* <option value="rejected">Rejected</option> */}
            <option value="printing">Printing</option>
            <option value="printed">Printed</option>
            {/* <option value="dispatch">Dispatch</option> */}
            {/* <option value="closed">Closed</option> */}
          </select>

          <div className="mt-1 mr-5 w-40 ml-5">
           
            <input
              type="month"
              id="month"
              class="form-input"
              name="month"
              value={selectedMonth}
              onChange={(e) => {
                setSelectedMonth(e.target.value); // Adding 1 to get month number from 1 to 12
              }}
            />
          </div>

          <button className="ml-5 btn btntheme" onClick={handleFilterMethod}>
            Let's start
          </button>
        </div>

        <div
          className="flex tableheader"
          style={{ justifyContent: "space-between" }}
        >
          <div>#</div>
          <div>Order ID</div>
          {/* <div>Course Name</div> */}
          {/* <div>Semester Name</div> */}
          <div>Order By</div>
          <div>Books Quantity</div>
          <div>Total Price</div>

          <div>Status</div>
          <div>Order Date</div>
          <div>Action</div>
        </div>
        <div className="tablebody">
          {filtered.map((order, index) => (
            <React.Fragment key={order.id}>
              <div
                className="flex"
                style={{
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <input
                    type="checkbox"
                    checked={checkedRows.includes(order.id)}
                    onChange={() => handleCheckboxChange(order.id)}
                  />
                </div>
                <div>
                  <span className="my-des-alt">{order.orderId}</span>
                </div>
                {/* <div>
                  <span className="my-des-alt">{order.course}</span>
                </div> */}
                {/* <div>
                  <span className="my-des-alt">{order.semester}</span>
                </div> */}
                <div>
                  <span className="my-des-alt">{order.orderBy}</span>
                </div>
                <div>
                  <span className="my-des-alt">{order.totalCount}</span>
                </div>
                <div>
                  <span className="my-des-alt">{order.totalPrice}</span>
                </div>

                <div>
                  <span className="my-des-alt">{order.currentStatus}</span>
                </div>
                <div>
                  <span className="my-des-alt">
                    {order?.createdDate?.split("T")[0]}
                  </span>
                </div>
                <div>
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => toggleRow(index)}
                  >
                    <i className="fa fa-eye" aria-hidden="true"></i>
                  </span>
                  {/* <span style={{ marginLeft: 10 }}>
                    <a
                      href={contentUrl + order?.paymentSlip}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i class="fa-solid fa-receipt"></i>
                    </a>
                  </span> */}
                </div>
              </div>
              {expandedRow === index && (
                <>
                  <table border={1} style={{ width: "100%" }}>
                    <thead>
                      <th>book name</th>
                      <th>course</th>
                      <th>semester</th>
                      <th>quantity</th>
                      <th>unit price</th>
                      <th>total price</th>
                    </thead>
                    <tbody>
                      {order?.orderItems.map((e) => (
                        <tr style={{ textAlign: "center" }}>
                          <td>{e?.bookname}</td>
                          <td>{e?.course}</td>
                          <td>{e?.semester}</td>
                          <td>{e?.orderQTY}</td>
                          <td>{e?.unitPrice}</td>
                          <td>{e?.totalPrice}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </>
              )}
            </React.Fragment>
          ))}
          {/* {loading && <p>Loading...</p>}
          {!loading && formValues.length === 0 && <p>No data found.</p>}
          {!loading && formValues.length > 0 && (
            <>
              {filtered.map((e, i) => (
                <div
                  className="flex"
                  key={i}
                  style={{ width: "100%", justifyContent: "space-between" }}
                >
                  <input
                    type="checkbox"
                    checked={checkedRows.includes(e.id)}
                    onChange={() => handleCheckboxChange(e.id)}
                  />
                  <div>
                    <span className="my-des-alt">{e.bookname}</span>
                  </div>
                  <div>
                    <span className="my-des-alt">{e.course}</span>
                  </div>
                  <div>
                    <span className="my-des-alt">{e.semester}</span>
                  </div>
                  <div>
                    <span className="my-des-alt">{e.orderBy}</span>
                  </div>

                  <div>
                    <span className="my-des-alt">{e.orderQTY}</span>
                  </div>

                  <div>
                    <span className="my-des-alt">{e.unitPrice}</span>
                  </div>

                  <div>
                    <span className="my-des-alt">{e.totalPrice}</span>
                  </div>

                
                  <div>
                    <span className="my-des-alt">{e.currentStatus}</span>
                  </div>

                  <div>
                   
                  </div>
                </div>
              ))}
              <ReactPaginate
                breakLabel="..."
                nextLabel="&raquo;"
                onPageChange={handlePage}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel="&laquo;"
                renderOnZeroPageCount={null}
                containerClassName="mars-pagination"
                pageClassName="mars-page"
                nextClassName="mars-page"
                previousClassName="mars-page"
                activeClassName="active"
              />
            </>
          )} */}
        </div>
      </div>
    </>
  );
};

export default Orders;

import React, { useEffect, useState } from "react";
// import axios from 'axios';

import * as Yup from "yup";

import { toast } from "react-toastify";
import { Axios } from "../../Services/Axios";
import { useFormik } from "formik";
import ReactPaginate from "react-paginate";

export const Cities = () => {
  const [formValues, setFormValues] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filtered, setFiltered] = React.useState([]);
  const [itemOffset, setItemOffset] = useState(0);
  const pageCount = Math.ceil(formValues.length / 10);

  useEffect(() => {
    const endOffset = itemOffset + 10;
    console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    setFiltered(formValues.slice(itemOffset, endOffset));
  }, [itemOffset, formValues]);

  const handlePage = (event) => {
    const newOffset = (event.selected * 10) % formValues.length;
    setItemOffset(newOffset);
  };
  const initialValues = {
    name: "",
    id: null,
  };

  useEffect(() => {
    GetAllCenters();
  }, []);

  const GetAllCenters = () => {
    setLoading(true);
    Axios.get("/City/GetAll").then(
      (res) => {
        setLoading(false);
        setFormValues(res.data);
      },
      () => {}
    );
  };

  const Edit = (r) => {
    formik.setValues(r);
    openModal();
  };
  const Delete = (id) => {
    Axios.delete(`/City/Remove?id=${id}`).then(
      () => {
        setFormValues((e) => [...e.filter((x) => x.id !== id)]);
        toast.success("Data Deleted Successfully...");
      },
      () => {
        toast.error("Something went wrong...");
      }
    );
  };

  const [open, setOpen] = useState(false);
  const openModal = () => {
    console.log(open);
    if (open) {
      formik.resetForm();
    }
    setOpen(!open);
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
  });
  const handleSubmit = async (values, { resetForm }) => {
    const payload = new FormData();
    const keys = Object.keys(values);
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      if (values[key]) {
        payload.append(key, values[key]);
      }
    }
    console.log(formik.values);
    if (formik.values.id) {
      Axios.put("/City/Update", payload, {
        headers: { "Content-Type": "multipart/form-data" },
      }).then(
        (res) => {
          setFormValues([
            ...formValues.filter((x) => x.id === res.data.id),
            res.data,
          ]);
          resetForm();
          setOpen(!open);
          toast.success("Data Updated successfully");
        },
        (err) => {
          toast.error("Something went wrong...");
        }
      );
    } else {
      Axios.post("/City/Add", payload, {
        headers: { "Content-Type": "multipart/form-data" },
      }).then(
        (res) => {
          setFormValues([...formValues, res.data]);
          resetForm();
          setOpen(!open);
          toast.success("Data inserted successfully");
        },
        (err) => {
          toast.error("Something went wrong...");
        }
      );
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  return (
    <>
      <div className="pageTitle">
        <div>
          <h3>Cities</h3>
        </div>
        <button className="btn btntheme" onClick={openModal}>
          Add City
        </button>
      </div>
      <div className="table">
        <div className="flex tableheader">
          <div>Name</div>
          <div>Action</div>
        </div>
        <div className="tablebody">
          {loading && <p>Loading...</p>}
          {!loading && formValues.length === 0 && <p>No data found.</p>}
          {!loading && formValues.length > 0 && (
            <>
              {filtered.map((e, i) => (
                <div className="flex" key={i}>
                  <div>
                    <span className="my-des-alt">{e.name}</span>
                  </div>
                  <div>
                    <span className="mx-2" onClick={() => Edit(e)}>
                      <i className="fa-solid fa-pen-to-square"></i>
                    </span>
                    <span className="mx-2" onClick={() => Delete(e.id)}>
                      <i className="fa-solid fa-trash-can"></i>
                    </span>
                  </div>
                </div>
              ))}
              <ReactPaginate
                breakLabel="..."
                nextLabel="&raquo;"
                onPageChange={handlePage}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel="&laquo;"
                renderOnZeroPageCount={null}
                containerClassName="mars-pagination"
                pageClassName="mars-page"
                nextClassName="mars-page"
                previousClassName="mars-page"
                activeClassName="active"
              />
            </>
          )}
        </div>
      </div>
      {open && (
        <div className="popup_overlay">
          <div className="popup_notify">
            <div className="popup_header">
              <h4>Add City</h4>
              <button className="btn" onClick={openModal}>
                <i className="fa-solid fa-xmark"></i>
              </button>
            </div>
            <div className="popup_body">
              <form onSubmit={formik.handleSubmit}>
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  id="name"
                  className="input"
                  name="name"
                  placeholder="eg. Karachi"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <small className="error">
                  {formik.touched.name && formik.errors.name}
                </small>

                <div className="popup_footer">
                  <button
                    className="btn btnclose"
                    onClick={openModal}
                    type="button"
                  >
                    Close
                  </button>
                  <button type="submit" className="btn btntheme">
                    Save
                  </button>
                </div>
              </form>
            </div>
            {/* <div className="popup_footer">
                <button className="btn btnclose"  onClick={openModal}>Close</button>
                <button className="btn btntheme">Save</button>
            </div> */}
          </div>
        </div>
      )}
    </>
  );
};

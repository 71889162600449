import { EncryptStorage } from "encrypt-storage";

export const Storage = new EncryptStorage("2932B54F-C838-4EFE-A04A-ACDDD2D98B10");

export const setItem = (key, value) => {
  let x = JSON.stringify(value)
  sessionStorage.setItem(key, x);
};

export const getItem = (key) => {
  let z = sessionStorage.getItem(key);
  let c = JSON.parse(z);
  return c;
};
export const getEItem = (key) => {
  let z = sessionStorage.getItem(key);
  let c = JSON.parse(z);
  return c
};

export const clear = () => {
  return sessionStorage.clear();
};

export const removeItem = (key) => {
  return sessionStorage.removeItem(key);
};

export const token = () => {
  return sessionStorage.getItem("token");
};

export const encryptData = (value) => {
  return value;
};
export const decryptData = (value) => {
  if (value) return value
  else return null;
};

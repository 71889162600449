import React, { useEffect, useState } from "react";
// import axios from 'axios';

import * as Yup from "yup";

import { useFormik } from "formik";
import ReactPaginate from "react-paginate";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Axios, contentUrl } from "../../Services/Axios";
import { decryptData } from "../../Services/Storage";

export const SOM = () => {
  const center = useSelector((e) => e.Center);
  const [formValues, setFormValues] = useState([]);
  const [centers, setCenters] = useState([]);
  const [loading, setLoading] = useState(false);
  const [image, setImage] = React.useState(null);
  const [filtered, setFiltered] = React.useState([]);
  const [itemOffset, setItemOffset] = useState(0);
  const pageCount = Math.ceil(formValues.length / 10);

  useEffect(() => {
    const endOffset = itemOffset + 10;
    console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    setFiltered(formValues.slice(itemOffset, endOffset));
  }, [itemOffset, formValues]);

  const handlePage = (event) => {
    const newOffset = (event.selected * 10) % formValues.length;
    setItemOffset(newOffset);
  };

  const initialValues = {
    id: null,
    studentName: "",
    batchCode: "",
    semester: "",
    image: "",
    month: "",
    centresId: Boolean(center) ? decryptData(center)?.id : "",
  };

  const validationSchema = Yup.object().shape({
    studentName: Yup.string().required("This field is required..."),
    batchCode: Yup.string().required("This field is required..."),
    semester: Yup.string().required("This field is required..."),
    image: Yup.string().required("This field is required..."),
    month: Yup.string().required("This field is required..."),
    centresId: Yup.string().required("This field is required..."),
  });
  useEffect(() => {
    if (Boolean(center)) {
      formik.setFieldValue("centresId", decryptData(center)?.id);
    }
    GetSOM();
  }, [center]);

  const GetSOM = () => {
    setLoading(true);
    Axios.get(
      `/SOM/GetByCenterId?Id=${
        Boolean(center)
          ? decryptData(center)?.id
          : "00000000-0000-0000-0000-000000000000"
      }`
    ).then(
      (res) => {
        GetCenters();
        setFormValues(res.data?.reverse());
      },
      () => {}
    );
  };

  const GetCenters = () => {
    Axios.get(`/Centres/GetAll`).then(
      (res) => {
        setLoading(false);
        setCenters(res.data);
      },
      () => {}
    );
  };

  const Edit = (r) => {
    formik.setValues(r);
    openModal();
  };
  const Delete = (id) => {
    Axios.delete(`/SOM/Remove?id=${id}`).then(
      () => {
        setFormValues((e) => [...e.filter((x) => x.id !== id)]);
        toast.success("Data Deleted Successfully...");
      },
      () => {
        toast.error("Something went wrong...");
      }
    );
  };

  const [open, setOpen] = useState(false);
  const openModal = () => {
    console.log(open);
    if (open) {
      formik.resetForm();
    }
    setOpen(!open);
  };

  const handleSubmit = async (values, { resetForm }) => {
    const payload = new FormData();
    const keys = Object.keys(values);
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      if (values[key] && key !== "image") {
        payload.append(key, values[key]);
      }
    }
    if (image) {
      payload.append("image", image);
    }
    console.log(formik.values);
    if (formik.values.id) {
      Axios.put("/SOM/Update", payload, {
        headers: { "Content-Type": "multipart/form-data" },
      }).then(
        (res) => {
          setFormValues((e) => [
            ...e.filter((x) => x.id !== res.data.id),
            res.data,
          ]);
          resetForm();
          setOpen(!open);
          toast.success("Data Updated successfully");
        },
        (err) => {
          toast.error("Something went wrong...");
        }
      );
    } else {
      Axios.post("/SOM/Add", payload, {
        headers: { "Content-Type": "multipart/form-data" },
      }).then(
        (res) => {
          setFormValues((e) => [...e, res.data]);
          resetForm();
          setOpen(!open);
          toast.success("Data inserted successfully");
        },
        (err) => {
          toast.error("Something went wrong...");
        }
      );
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  return (
    <>
      <div className="pageTitle">
        <div>
          <h3>SOM</h3>
        </div>
        <button className="btn btntheme" onClick={openModal}>
          Add SOM
        </button>
      </div>
      <div className="table">
        <div className="flex tableheader">
          <div>Image</div>
          <div>Student Name</div>
          <div>Batch Code</div>
          <div>Semester</div>
          <div>Month</div>
          <div>Center</div>
          <div>Action</div>
        </div>
        <div className="tablebody">
          {loading && <p>Loading...</p>}
          {!loading && formValues.length === 0 && <p>No data found.</p>}
          {!loading && formValues.length > 0 && (
            <>
              {filtered.map((e, i) => (
                <div className="flex" key={i}>
                  <div>
                    <img
                      src={contentUrl + e.image}
                      alt=""
                      style={{ width: "100px" }}
                    />
                  </div>
                  <div>
                    <span className="my-des-alt">{e.studentName}</span>
                  </div>
                  <div>
                    <span className="my-des-alt">{e.batchCode}</span>
                  </div>
                  <div>
                    <span className="my-des-alt">{e.semester}</span>
                  </div>
                  <div>
                    <span className="my-des-alt">{e.month}</span>
                  </div>
                  <div>
                    <span className="my-des-alt">{e.centres}</span>
                  </div>
                  <div>
                    <span className="mx-2" onClick={() => Edit(e)}>
                      <i className="fa-solid fa-pen-to-square"></i>
                    </span>
                    <span className="mx-2" onClick={() => Delete(e.id)}>
                      <i className="fa-solid fa-trash-can"></i>
                    </span>
                  </div>
                </div>
              ))}
              <ReactPaginate
                breakLabel="..."
                nextLabel="&raquo;"
                onPageChange={handlePage}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel="&laquo;"
                renderOnZeroPageCount={null}
                containerClassName="mars-pagination"
                pageClassName="mars-page"
                nextClassName="mars-page"
                previousClassName="mars-page"
                activeClassName="active"
              />
            </>
          )}
        </div>
      </div>
      {open && (
        <div className="popup_overlay">
          <div className="popup_notify">
            <div className="popup_header">
              <h4>Add SOM</h4>
              <button className="btn" onClick={openModal}>
                <i className="fa-solid fa-xmark"></i>
              </button>
            </div>
            <div className="popup_body">
              <form onSubmit={formik.handleSubmit}>
                <label htmlFor="studentName">Student Name</label>
                <input
                  type="text"
                  id="studentName"
                  className="input"
                  name="studentName"
                  value={formik.values.studentName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <small className="error">
                  {formik.touched.studentName && formik.errors.studentName}
                </small>

                <label htmlFor="batchCode">Batch Code</label>
                <input
                  type="text"
                  id="batchCode"
                  className="input"
                  name="batchCode"
                  value={formik.values.batchCode}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <small className="error">
                  {formik.touched.batchCode && formik.errors.batchCode}
                </small>

                <label htmlFor="semester">Semester</label>
                <input
                  type="text"
                  id="semester"
                  className="input"
                  name="semester"
                  value={formik.values.semester}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <small className="error">
                  {formik.touched.semester && formik.errors.semester}
                </small>

                <label htmlFor="description">Description</label>
                <input
                  type="text"
                  id="description"
                  className="input"
                  name="description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <small className="error">
                  {formik.touched.description && formik.errors.description}
                </small>

                <label htmlFor="month">Month</label>
                <input
                  type="month"
                  id="month"
                  className="input"
                  name="month"
                  value={formik.values.month}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <small className="error">
                  {formik.touched.month && formik.errors.month}
                </small>

                <label htmlFor="centresId">Center</label>
                <select
                  name="centresId"
                  id="centresId"
                  className="input"
                  value={formik.values?.centresId}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled={Boolean(center)}
                >
                  <option value="" disabled>
                    Select Center
                  </option>
                  {centers.map((e) => (
                    <option value={e.id} key={e.id}>
                      {e.centreName}
                    </option>
                  ))}
                </select>
                <small className="error">
                  {formik.touched.centresId && formik.errors.centresId}
                </small>
                <label htmlFor="image">Image</label>
                <input
                  type="file"
                  id="image"
                  className="input"
                  name="image"
                  accept="image/*"
                  onChange={(e) => {
                    formik.setFieldValue("image", e.target.value);
                    setImage(e.target.files[0]);
                  }}
                  onBlur={formik.handleBlur}
                />
                <small className="error">
                  {formik.touched.image && formik.errors.image}
                </small>

                <div className="popup_footer">
                  <button
                    className="btn btnclose"
                    onClick={openModal}
                    type="button"
                  >
                    Close
                  </button>
                  <button type="submit" className="btn btntheme">
                    Save
                  </button>
                </div>
              </form>
            </div>
            {/* <div className="popup_footer">
                <button className="btn btnclose"  onClick={openModal}>Close</button>
                <button className="btn btntheme">Save</button>
            </div> */}
          </div>
        </div>
      )}
    </>
  );
};

import React, { useEffect, useState } from "react";
// import axios from 'axios';

import * as Yup from "yup";
import { useSelector } from "react-redux";
import { decryptData } from "../../Services/Storage";

import { toast } from "react-toastify";
import { Axios, contentUrl } from "../../Services/Axios";
import { useFormik, FormikProvider, FieldArray, ErrorMessage } from "formik";
import ReactPaginate from "react-paginate";

export const Events = () => {
  const [formValues, setFormValues] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [filtered, setFiltered] = React.useState([]);
  const [itemOffset, setItemOffset] = useState(0);
  const pageCount = Math.ceil(formValues.length / 10);
  const center = useSelector((e) => e.Center);

  useEffect(() => {
    const endOffset = itemOffset + 10;
    setFiltered(formValues.slice(itemOffset, endOffset));
  }, [itemOffset, formValues]);

  const handlePage = (event) => {
    const newOffset = (event.selected * 10) % formValues.length;
    setItemOffset(newOffset);
  };

  const types = [
    { key: "youtube", value: "Youtube" },
    { key: "linkedin", value: "LinkedIn" },
    { key: "instagram", value: "Instagram" },
    { key: "facebook", value: "Facebook" },
  ];
  const initialValues = {
    id: null,
    name: "",
    centresId: null,
    eventLinks: [
      {
        id: null,
        link: "",
        imgUrl: "",
        type: "",
      },
    ],
  };

  const GetAllEvents = () => {
    setLoading(true);
    Axios.get(`/Events/GetByCenterId?Id=${decryptData(center)?.id}`).then(
      (res) => {
        setLoading(false);
        setFormValues(res.data);
      },
      () => {}
    );
  };

  const GetGlobal = () => {
    setLoading(true);
    Axios.get(`/Events/GetGlobal`).then(
      (res) => {
        setLoading(false);
        setFormValues(res.data);
      },
      () => {}
    );
  };

  const Edit = (r) => {
    console.log(r);
    formik.setValues(r);
    openModal();
  };
  const Delete = (id) => {
    Axios.delete(`/Events/Remove?id=${id}`).then(
      () => {
        setFormValues((e) => [...e.filter((x) => x.id !== id)]);
        toast.success("Data Deleted Successfully...");
      },
      () => {
        toast.error("Something went wrong...");
      }
    );
  };
  const LinkDelete = (id, e, i) => {
    if (id) {
      Axios.delete(`/EventLinks/Remove?id=${id}`).then(
        () => {
          e(i);
        },
        () => toast.error("Something went wrong...")
      );
    } else e(i);
  };

  const [open, setOpen] = useState(false);
  const openModal = () => {
    if (open) {
      formik.resetForm();
      setLoading1(false);
    }
    setOpen(!open);
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    eventLinks: Yup.array().of(
      Yup.object().shape({
        type: Yup.string().required("Type is required"),
        link: Yup.string().required("Link is required"),
        imgUrl: Yup.string().required("Link is required"),
      })
    ),
  });
  const handleSubmit = async (values, actions) => {
    setLoading1(true)
    const { id, name, status, centresId, eventLinks } = values;
    const eventFormData = new FormData();
    eventFormData.append("id", id);
    eventFormData.append("name", name);
    // eventFormData.append("status", status);
    if(decryptData(center)?.id){
      eventFormData.append("centresId", centresId);
    }

    const eventLinksFormData = new FormData();
    eventLinks.forEach((link, index) => {
      Object.keys(link).forEach((key) => {
        eventLinksFormData.append(`${key}`, link[key]);
      });
    });

    if (formik.values.id) {

      Axios.put("/Events/Update", eventFormData, {
        // headers: { "Content-Type": "multipart/form-data" },
      }).then(
        (resx) => {
          eventLinksFormData.append('eventsId',resx.data?.id)
          Axios.put('/EventLinks/Update', eventLinksFormData, {
            headers: { "Content-Type": "multipart/form-data" },
          }).then((res)=>{
            // setFormValues([
            //   ...formValues.filter((x) => x.id !== res.data.id),
            //   res.data,
            // ]);
            // resetForm();
            setLoading1(false)
            GetEventsAfterAdd();
            setOpen(!open);
            toast.success("Data Updated successfully");
            actions.resetForm();
            formik.setFieldValue('centresId',decryptData(center)?.id)


          }).catch((err)=>console.log(err))


         
        },
        (err) => {
          toast.error("Something went wrong...");
        }
      );
    } else {
      eventFormData.delete("id");
      eventLinksFormData.delete("id");
      Axios.post("/Events/Add", eventFormData, {
        // headers: { "Content-Type": "multipart/form-data" },
      }).then(
        (resx) => {
          eventLinksFormData.append('eventsId',resx.data?.id)
          Axios.post('/EventLinks/Add', eventLinksFormData, {
            headers: { "Content-Type": "multipart/form-data" },
          }).then((res)=>{
            setFormValues([...formValues, resx.data]);
            // resetForm();
            GetEventsAfterAdd();
            setLoading1(false)
            setOpen(!open);
            toast.success("Data inserted successfully");
            actions.resetForm();
            formik.setFieldValue('centresId',decryptData(center)?.id)


          }).catch((err)=>console.log(err))

          
        },
        (err) => {
          toast.error("Something went wrong...");
        }
      );
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  useEffect(() => {
    if (Boolean(center)) {
      formik.setFieldValue("centresId", decryptData(center)?.id);
      GetAllEvents();
    } else {
      GetGlobal();
    }
  }, [center]);

  const GetEventsAfterAdd = ()=>{
    if (Boolean(center)) {
      formik.setFieldValue("centresId", decryptData(center)?.id);
      GetAllEvents();
    } else {
      GetGlobal();
    }
  }

  const handleImageUpload = (e, index) => {
    const file = e.target.files[0];
    formik.setFieldValue(`eventLinks.[${index}].imgUrl`, file);
  };

  return (
    <>
      <div className="pageTitle">
        <div>
          <h3>Events</h3>
        </div>
        <button className="btn btntheme" onClick={openModal}>
          Add Event
        </button>
      </div>
      <div className="table">
        <div
          className="flex tableheader"
          style={{ justifyContent: "space-between" }}
        >
          <div>Title</div>
          <div>Event Link</div>
          <div>Event Thumbnail</div>

          <div>Action</div>
        </div>
        <div className="tablebody">
          {loading && <p>Loading...</p>}
          {!loading && formValues.length === 0 && <p>No data found.</p>}
          {!loading && formValues.length > 0 && (
            <>
              {filtered.map((e, i) => (
                <div
                  className="flex"
                  key={i}
                  style={{ justifyContent: "space-between" }}
                >
                  <div>
                    <span className="my-des-alt">{e.name}</span>
                  </div>
                  <div>
                    {e.eventLinks.map((z, zi) => (
                      <a
                        key={zi}
                        className="my-des-alt"
                        href={z.link}
                        target="_blank"
                      >
                        {z.link}
                      </a>
                    ))}
                  </div>

                  <div>
                    {e.eventLinks.map((z, zi) => (
                      <img
                        key={zi}
                        className="my-des-alt"
                        src={contentUrl + z.imgUrl}
                        height={"100px"}
                        width={"100px"}
                        style={{ objectFit: "cover" }}
                      />
                    ))}
                  </div>
                  <div>
                    <span className="mx-2" onClick={() => Edit(e)}>
                      <i className="fa-solid fa-pen-to-square"></i>
                    </span>
                    <span className="mx-2" onClick={() => Delete(e.id)}>
                      <i className="fa-solid fa-trash-can"></i>
                    </span>
                  </div>
                </div>
              ))}
              <ReactPaginate
                breakLabel="..."
                nextLabel="&raquo;"
                onPageChange={handlePage}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel="&laquo;"
                renderOnZeroPageCount={null}
                containerClassName="mars-pagination"
                pageClassName="mars-page"
                nextClassName="mars-page"
                previousClassName="mars-page"
                activeClassName="active"
              />
            </>
          )}
        </div>
      </div>
      {open && (
        <div className="popup_overlay">
          <FormikProvider value={formik}>
            <div className="popup_notify">
              <div className="popup_header">
                <h4>Add Event</h4>
                <button className="btn" onClick={openModal}>
                  <i className="fa-solid fa-xmark"></i>
                </button>
              </div>
              <div className="popup_body">
                <form onSubmit={formik.handleSubmit}>
                  <div>
                    <label htmlFor="name">Name</label>
                    <input
                      type="text"
                      id="name"
                      className="input"
                      name="name"
                      placeholder="eg. Aptech Vision"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <small className="error">
                      {formik.touched.name && formik.errors.name}
                    </small>
                  </div>

                  <FieldArray name="eventLinks">
                    {({ insert, remove, push }) => (
                      <>
                        {formik.values.eventLinks.map((e, i) => (
                          <div key={i}>
                            <div
                              style={{
                                marginTop: "15px",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                fontSize: "1rem",
                              }}
                            >
                              <label style={{ margin: 0 }}>Link {i + 1}</label>
                              {i !== 0 ? (
                                <i
                                  className="fas fa-trash-alt text-danger"
                                  onClick={() => LinkDelete(e.id, remove, i)}
                                ></i>
                              ) : (
                                <></>
                              )}
                            </div>
                            <div style={{ padding: "0px 15px" }}>
                              <label htmlFor={`eventLinks.[${i}].type`}>
                                Link Type
                              </label>
                              <select
                                id={`eventLinks.[${i}].type`}
                                className="input"
                                name={`eventLinks.[${i}].type`}
                                value={formik.values.eventLinks[i].type}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              >
                                <option value="" disabled>
                                  Select Link Type
                                </option>
                                {types.map((x, xi) => (
                                  <option value={x.key} key={xi}>
                                    {x.value}
                                  </option>
                                ))}
                              </select>
                              <ErrorMessage
                                name={`eventLinks.${i}.type`}
                                component="small"
                                className="error"
                              />

                              <label htmlFor={`eventLinks.[${i}].link`}>
                                Url
                              </label>
                              <input
                                type="text"
                                id={`eventLinks.[${i}].link`}
                                className="input"
                                name={`eventLinks.[${i}].link`}
                                value={formik.values.eventLinks[i].link}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              />
                              <ErrorMessage
                                name={`eventLinks.${i}.link`}
                                component="small"
                                className="error"
                              />

                              <label htmlFor={`eventLinks.[${i}].imgUrl`}>
                                Image Url
                              </label>
                              <input
                                type="file"
                                id={`eventLinks.[${i}].imgUrl`}
                                className="input"
                                name={`eventLinks.[${i}].imgUrl`}
                                onChange={(e) => handleImageUpload(e, i)}
                                onBlur={formik.handleBlur}
                              />
                              <ErrorMessage
                                name={`eventLinks.${i}.imgUrl`}
                                component="small"
                                className="error"
                              />
                            </div>
                          </div>
                        ))}
                        {/* <div
                          className="text-end"
                          style={{ margin: "10px 15px" }}
                        >
                          <button
                            className="btn btntheme"
                            type="button"
                            onClick={() => push({ name: "", email: "" })}
                          >
                            Add Another Link
                          </button>
                        </div> */}
                      </>
                    )}
                  </FieldArray>

                  <div className="popup_footer">
                    <button
                      className="btn btnclose"
                      onClick={openModal}
                      type="button"
                    >
                      Close
                    </button>
                    <button type="submit" className="btn btntheme">
                      {loading1 ? 'Loading...' : 'Save'}
                    </button>
                  </div>
                </form>
              </div>
              {/* <div className="popup_footer">
                <button className="btn btnclose"  onClick={openModal}>Close</button>
                <button className="btn btntheme">Save</button>
            </div> */}
            </div>
          </FormikProvider>
        </div>
      )}
    </>
  );
};

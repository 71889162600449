import React from "react";
import ReactDOM from "react-dom/client";
import { createStore } from "redux";
import { HashRouter } from "react-router-dom";
import App from "./App";
import { rootReducer } from "./Services/Redux";
import { Provider } from "react-redux";

const store = createStore(
  rootReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <HashRouter>
      <App />
    </HashRouter>
  </Provider>
);

import React, { useEffect, useState } from "react";
import { Importer, ImporterField } from "react-csv-importer";
import "react-csv-importer/dist/index.css";
import moment from "moment";
import { Axios } from "../../Services/Axios";

const ImportBooks = ({ setOpen, open }) => {
  const [semester, setSemester] = useState([]);

  const getSemester = () => {
    Axios.get(`/Semester/GetAll`).then((res) => {
      setSemester(res.data);
      console.log(res.data);
    });
  };
  function findClosestMatch(semesterName, courseName) {
    const normalizedSemesterName = semesterName.trim().toLowerCase();
    const normalizedCourseName = courseName.trim().toLowerCase();
    let closestMatch = null;
    let minDistance = Infinity;

    semester.forEach((semester) => {
      const semesterDistance = levenshteinDistance(
        normalizedSemesterName,
        semester.name.trim().toLowerCase()
      );
      const courseDistance = levenshteinDistance(
        normalizedCourseName,
        semester.course.name.trim().toLowerCase()
      );
      // Calculate combined distance
      const distance = semesterDistance + courseDistance;

      if (distance < minDistance) {
        minDistance = distance;
        closestMatch = semester;
      }
    });

    // You can adjust the threshold as needed
    if (minDistance <= 6) {
      // Adjusted threshold to account for both names
      return closestMatch;
    } else {
      return null; // No close match found
    }
  }

  function levenshteinDistance(a, b) {
    const matrix = [];
    const aLength = a.length;
    const bLength = b.length;

    if (aLength === 0) return bLength;
    if (bLength === 0) return aLength;

    // Initialize matrix with indexes
    for (let i = 0; i <= bLength; i++) {
      matrix[i] = [i];
    }
    for (let j = 0; j <= aLength; j++) {
      matrix[0][j] = j;
    }

    // Calculate Levenshtein distance
    for (let i = 1; i <= bLength; i++) {
      for (let j = 1; j <= aLength; j++) {
        const cost = a[j - 1] === b[i - 1] ? 0 : 1;
        matrix[i][j] = Math.min(
          matrix[i - 1][j] + 1, // deletion
          matrix[i][j - 1] + 1, // insertion
          matrix[i - 1][j - 1] + cost // substitution
        );
      }
    }

    return matrix[bLength][aLength];
  }

  useEffect(() => {
    getSemester();
  }, []);

  return (
    <div className="mt-5 ml-5 mr-5">
      <Importer
        defaultNoHeader={false}
        restartable={false}
        dataHandler={async (rows) => {
          // console.log(rows)
          rows.forEach((obj) => {
            // console.log(obj);
            const matchedTrade = findClosestMatch(obj.semesterId, obj.courseId);
            console.log(matchedTrade);
            if (matchedTrade) {
              Object.assign(obj, {
                semesterId: matchedTrade?.id,
                courseId: matchedTrade?.courseId,
              });
            }
          });

          //   console.log(rows)

          await Axios.post("/Book/AddRange", rows);
        }}
        onComplete={({ file, preview, fields, columnFields }) => {
          setOpen(false);
        }}
      >
        <ImporterField name="courseId" label="course" />
        <ImporterField name="semesterId" label="semester" />
        <ImporterField name="bookName" label="book name" />
        <ImporterField name="priceKhi" label="price khi" />
        <ImporterField name="priceRop" label="price rop" />
      </Importer>
    </div>
  );
};

export default ImportBooks;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { Login } from "../Pages/Auth/Login";
import { Centers } from "../Pages/Centers";
import { Cities } from "../Pages/Cities";
// import { Dashboard } from "../Pages/Dashboard";
import { Inquiry } from "../Pages/Inquiry";
import { POM } from "../Pages/POM";
import { Placements } from "../Pages/Placements";
import { Axios } from "../Services/Axios";
import { clear, decryptData, encryptData } from "../Services/Storage";
import { SOM } from "../Pages/SOM";
import { UpcomingBatches } from "../Pages/UpcomingBatches";
import { Events } from "../Pages/Events";
import OMS from "../Pages/OMS/Index";
import OMSCENTER from "../Pages/OMSCENTER/Index";
import CHECKOUT from "../Pages/OMSCENTER/Checkout";
import Course from "../Pages/Courses/Index";
import Semester from "../Pages/Semester/Index";
import Orders from "../Pages/Orders/Index";
import VenderOrders from "../Pages/Vendor/Orders";
import CenterOrders from "../Pages/OMSCENTER/Orders";

// import { Events } from "../Pages/Events/index";
// const routes = [
//   { path: "/", element: <Home value="Dashboard" /> },
//   { path: "/inquiry", element: <Inquiry value="Course Inquiry" /> },
//   { path: "/centre", element: <Centre value="Course Inquiry" /> },
//   { path: "/batches", element: <Batches value="" /> },
//   { path: "/som", element: <Som value="" /> },
//   { path: "/pom", element: <Pom value="" /> },
//   { path: "/placement", element: <Placement value="" /> },
//   { path: "/events", element: <Events value="" /> },
//   { path: "/videos", element: <Videos value="" /> },
//   { path: "/alumni", element: <Alumni value="" /> },
//   { path: "/survey", element: <Survey value="" /> },
//   { path: "/complaint", element: <Complains value="" /> },
//   { path: "/login", element: <Login value="" /> },
// ];
export const Router = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const auth = useSelector((e) => e.LoggedIn);
  const role = decryptData(useSelector((e) => e.Role));
  const [loaded, setLoaded] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const center = useSelector((e) => e.Center);

  useEffect(() => {
    setLoggedIn(decryptData(auth));
    setLoaded(true);
  }, [auth]);
  // useEffect(() => {
  //   console.log(role?.role);
  // }, [role]);
  // useEffect(() => {
  //   console.log(loaded, loggedIn, "INR");
  //   if (loaded && loggedIn) {
  //     console.log("INserted");
  //     Axios.interceptors.request.use(
  //       async (config) => {
  //         config.headers = {
  //           ...config.headers,
  //           Authorization: `Bearer ${getItem("token")}`,
  //         };
  //         return config;
  //       },
  //       (err) => Promise.reject(err)
  //     );
  //   }
  // }, [loaded, loggedIn]);

  Axios.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      if (error?.response?.status === 401) {
        console.log("Nothing just hanging around.");
        clear();
        dispatch({ type: "setLogin", payload: encryptData(false) });
        dispatch({ type: "setLoader", payload: encryptData(false) });
        dispatch({ type: "setUser", payload: null });
        dispatch({ type: "setRole", payload: null });
        navigate("/login");
      }

      return Promise.reject(error);
    }
  );
  return loaded ? (
    <>
      <Routes>
        {loggedIn ? (
          <>
            {role?.role === "admin" ? (
              <>
                <Route path="/" element={<Navigate to="/course-inquiry" />} />
                <Route path="/course-inquiry" element={<Inquiry />} />
                <Route path="/placements" element={<Placements />} />
                <Route path="/Pom" element={<POM />} />
                <Route path="/Som" element={<SOM />} />
                <Route path="/upcoming-batches" element={<UpcomingBatches />} />
                <Route path="/events" element={<Events />} />
                <Route path="/centers" element={<Centers />} />
                <Route path="/cities" element={<Cities />} />
                <Route path="/oms" element={<OMS />} />
                <Route path="/courses" element={<Course />} />
                <Route path="/semester" element={<Semester />} />
                <Route path="/orders" element={<Orders />} />
              </>
            ) : (
              <>
                {role?.role === "center" ? (
                  <>
                    {/* {decryptData(center)?.id ===
                      "2f600f2a-54a9-45e1-fed7-08dc5fa28cd4" && (
                      <>
                        <Route
                          path="/"
                          element={<Navigate to="/oms-center" />}
                        />
                      </>
                    )} */}
                    <Route
                      path="/"
                      element={<Navigate to="/course-inquiry" />}
                    />
                    <Route path="/course-inquiry" element={<Inquiry />} />
                    <Route path="/placements" element={<Placements />} />
                    <Route path="/Pom" element={<POM />} />
                    <Route path="/Som" element={<SOM />} />
                    <Route
                      path="/upcoming-batches"
                      element={<UpcomingBatches />}
                    />
                    <Route path="/events" element={<Events />} />
                    <Route path="/oms-center" element={<OMSCENTER />} />
                    <Route path="/checkout" element={<CHECKOUT />} />
                    <Route path="/orders" element={<CenterOrders />} />
                  </>
                ) : (
                  <>
                    {role?.role === "OMS" && (
                      <Route path="/" element={<VenderOrders />} />
                    )}
                  </>
                )}
              </>
            )}

            <Route path="*" element={<Navigate to="/" />} />
          </>
        ) : (
          <>
            <Route path="/login" element={<Login />} />
            <Route path="*" element={<Navigate to="/login" />} />
          </>
        )}
      </Routes>
    </>
  ) : (
    <></>
  );
};

import React, { useEffect, useState } from "react";
// import axios from 'axios';

import * as Yup from "yup";

import { useFormik } from "formik";
import ReactPaginate from "react-paginate";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Axios } from "../../Services/Axios";
import { decryptData } from "../../Services/Storage";

export const UpcomingBatches = () => {
  const center = useSelector((e) => e.Center);
  const [formValues, setFormValues] = useState([]);
  const [centers, setCenters] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filtered, setFiltered] = React.useState([]);
  const [itemOffset, setItemOffset] = useState(0);
  const pageCount = Math.ceil(formValues.length / 10);

  useEffect(() => {
    const endOffset = itemOffset + 10;
    console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    setFiltered(formValues.slice(itemOffset, endOffset));
  }, [itemOffset, formValues]);

  const handlePage = (event) => {
    const newOffset = (event.selected * 10) % formValues.length;
    setItemOffset(newOffset);
  };
  const initialValues = {
    id: null,
    courseCode: "",
    courseName: "",
    startDate: "",
    timeSlot: "",
    days: "",
    status: "",
    centresId: Boolean(center) ? decryptData(center)?.id : "",
  };

  const validationSchema = Yup.object().shape({
    courseCode: Yup.string().required("This field is required..."),
    courseName: Yup.string().required("This field is required..."),
    startDate: Yup.string().required("This field is required..."),
    timeSlot: Yup.string().required("This field is required..."),
    days: Yup.string().required("This field is required..."),
    status: Yup.string().required("This field is required..."),
    centresId: Yup.string().required("This field is required..."),
  });

  const timeSlots = [
    "09 AM - 11 AM",
    "11 AM - 01 PM",
    "01 PM - 03 PM",
    "03 PM - 05 PM",
    "05 PM - 07 PM",
    "07 PM - 09 PM",
  ];
  const days = ["mwf", "tts"];

  useEffect(() => {
    if (Boolean(center)) {
      formik.setFieldValue("centresId", decryptData(center)?.id);
    }
    GetUpcomingBatches();
  }, [center]);

  const GetUpcomingBatches = () => {
    setLoading(true);
    Axios.get(
      `/UpcomingBatch/GetByCenterId?Id=${
        Boolean(center)
          ? decryptData(center)?.id
          : "00000000-0000-0000-0000-000000000000"
      }`
    ).then(
      (res) => {
        GetCenters();
        setFormValues(res.data);
      },
      () => {}
    );
  };

  const GetCenters = () => {
    Axios.get(`/Centres/GetAll`).then(
      (res) => {
        setLoading(false);
        setCenters(res.data);
      },
      () => {}
    );
  };

  const Edit = (r) => {
    formik.setValues(r);
    openModal();
  };
  const Delete = (id) => {
    Axios.delete(`/UpcomingBatch/Remove?id=${id}`).then(
      () => {
        setFormValues((e) => [...e.filter((x) => x.id !== id)]);
        toast.success("Data Deleted Successfully...");
      },
      () => {
        toast.error("Something went wrong...");
      }
    );
  };

  const [open, setOpen] = useState(false);
  const openModal = () => {
    console.log(open);
    if (open) {
      formik.resetForm();
    }
    setOpen(!open);
  };

  const handleSubmit = async (values, { resetForm }) => {
    const payload = new FormData();
    const keys = Object.keys(values);
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      if (values[key]) {
        payload.append(key, values[key]);
      }
    }
    console.log(formik.values);
    if (formik.values.id) {
      Axios.put("/UpcomingBatch/Update", payload).then(
        (res) => {
          setFormValues([
            ...formValues.filter((x) => x.id !== res.data.id),
            res.data,
          ]);
          resetForm();
          setOpen(!open);
          toast.success("Data Updated successfully");
        },
        (err) => {
          toast.error("Something went wrong...");
        }
      );
    } else {
      Axios.post("/UpcomingBatch/Add", payload).then(
        (res) => {
          setFormValues([...formValues, res.data]);
          resetForm();
          setOpen(!open);
          toast.success("Data inserted successfully");
        },
        (err) => {
          toast.error("Something went wrong...");
        }
      );
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });




  let courseNameCodesForImages = [
    {
      id:'ACCP',
      name:'ACCP'
    },
    {
      id:'DM',
      name:'Digital Marketing'
    },
    {
      id:'ENG',
      name:'English Language'
    },
    {
      id:'Amazon',
      name:'Amazon'
    },
    {
      id:'Android',
      name:'Android'
    },
    {
      id:'AutoCAD',
      name:'AutoCAD'
    },
    {
      id:'DAE',
      name:'DAE'
    },
    {
      id:'DotNet',
      name:'DotNet'
    },
    {
      id:'Java',
      name:'Java'
    },
    {
      id:'MSOffice',
      name:'MS Office'
    },
    {
      id:'PHP',
      name:'PHP'
    },
    {
      id:'Python',
      name:'Python'
    },
    {
      id:'webdesigning',
      name:'Web Designing'
    },
    {
      id:'WordPress',
      name:'WordPress'
    },
    {
      id:'Freelancing',
      name:'Freelancing'
    },
    {
      id:'uiux',
      name:'UI/UX'
    },
    {
      id: "shopify",
      name: "Shopify"
    },
    {
      id: "advanceExcel",
      name: "Advance Excel"
    },
    {
      id: "powerBi",
      name: "Power BI"
    },

  ]

  return (
    <>
      <div className="pageTitle">
        <div>
          <h3>Upcoming Batches</h3>
        </div>
        <button className="btn btntheme" onClick={openModal}>
          Add Upcoming Batch
        </button>
      </div>
      <div className="table">
        <div className="flex tableheader">
          <div>Batch Code</div>
          <div>Course Name</div>
          <div>Start Date</div>
          <div>Time Slot</div>
          <div>Days</div>
          <div>Center</div>
          <div>Status</div>
          <div>Action</div>
        </div>
        <div className="tablebody">
          {loading && <p>Loading...</p>}
          {!loading && formValues.length === 0 && <p>No data found.</p>}
          {!loading && formValues.length > 0 && (
            <>
              {filtered.map((e, i) => (
                <div className="flex" key={i}>
                  <div>
                    <span className="my-des-alt">{e.courseCode}</span>
                  </div>
                  <div>
                    <span className="my-des-alt">{e.courseName}</span>
                  </div>
                  <div>
                    <span className="my-des-alt">{e.startDate}</span>
                  </div>
                  <div>
                    <span className="my-des-alt">{e.timeSlot}</span>
                  </div>
                  <div>
                    <span className="my-des-alt">
                      {`${e.days}`.toUpperCase()}
                    </span>
                  </div>
                  <div>
                    <span className="my-des-alt">{e.centres}</span>
                  </div>
                  <div>
                    <span className="my-des-alt">
                      {e.status == "1"
                        ? "Limited Seats"
                        : e.status == "2"
                        ? "Admission Open"
                        : e.status == "3"
                        ? "Admission Closed"
                        : "---"}
                    </span>
                  </div>
                  <div>
                    <span className="mx-2" onClick={() => Edit(e)}>
                      <i className="fa-solid fa-pen-to-square"></i>
                    </span>
                    <span className="mx-2" onClick={() => Delete(e.id)}>
                      <i className="fa-solid fa-trash-can"></i>
                    </span>
                  </div>
                </div>
              ))}
              <ReactPaginate
                breakLabel="..."
                nextLabel="&raquo;"
                onPageChange={handlePage}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel="&laquo;"
                renderOnZeroPageCount={null}
                containerClassName="mars-pagination"
                pageClassName="mars-page"
                nextClassName="mars-page"
                previousClassName="mars-page"
                activeClassName="active"
              />
            </>
          )}
        </div>
      </div>
      {open && (
        <div className="popup_overlay">
          <div className="popup_notify">
            <div className="popup_header">
              <h4>Add Upcoming Batch</h4>
              <button className="btn" onClick={openModal}>
                <i className="fa-solid fa-xmark"></i>
              </button>
            </div>
            <div className="popup_body">
              <form onSubmit={formik.handleSubmit}>
                <label htmlFor="courseName">Course Name</label>
               
                 <select
                  name="courseName"
                  id="courseName"
                  className="input"
                  value={formik.values?.courseName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  <option value="" disabled>
                    Select Course Name
                  </option>
                  {courseNameCodesForImages.map((e) => (
                    <option value={e.id} key={e.id}>
                      {e.name}
                    </option>
                  ))}
                </select>
                <small className="error">
                  {formik.touched.courseName && formik.errors.courseName}
                </small>

                <label htmlFor="courseCode">Batch Code</label>
                <input
                  type="text"
                  id="courseCode"
                  className="input"
                  name="courseCode"
                  value={formik.values.courseCode}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <small className="error">
                  {formik.touched.courseCode && formik.errors.courseCode}
                </small>

                <label htmlFor="startDate">Start Date</label>
                <input
                  type="date"
                  id="startDate"
                  className="input"
                  name="startDate"
                  value={formik.values.startDate}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <small className="error">
                  {formik.touched.startDate && formik.errors.startDate}
                </small>

                <label htmlFor="timeSlot">Time Slot</label>
                <select
                  name="timeSlot"
                  id="timeSlot"
                  className="input"
                  value={formik.values?.timeSlot}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  <option value="" disabled>
                    Select Time Slot
                  </option>
                  {timeSlots.map((e) => (
                    <option value={e} key={e}>
                      {e}
                    </option>
                  ))}
                </select>
                <small className="error">
                  {formik.touched.timeSlot && formik.errors.timeSlot}
                </small>
                <label htmlFor="timeSlot">Days</label>
                <select
                  name="days"
                  id="days"
                  className="input"
                  value={formik.values?.days}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  <option value="" disabled>
                    Select Days
                  </option>
                  {days.map((e) => (
                    <option value={e} key={e}>
                      {`${e}`.toUpperCase()}
                    </option>
                  ))}
                </select>
                <small className="error">
                  {formik.touched.days && formik.errors.days}
                </small>

                <label htmlFor="timeSlot">Status</label>
                <select
                  name="status"
                  id="status"
                  className="input"
                  value={formik.values?.status}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  <option value="" disabled>
                    Select Status
                  </option>
                  <option value="1">Limited Seats</option>
                  <option value="2">Admission Open</option>
                  <option value="3">Admission Closed</option>
                </select>
                <small className="error">
                  {formik.touched.status && formik.errors.status}
                </small>

                <label htmlFor="centresId">Center</label>
                <select
                  name="centresId"
                  id="centresId"
                  className="input"
                  value={formik.values?.centresId}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled={Boolean(center)}
                >
                  <option value="" disabled>
                    Select Center
                  </option>
                  {centers.map((e) => (
                    <option value={e.id} key={e.id}>
                      {e.centreName}
                    </option>
                  ))}
                </select>
                <small className="error">
                  {formik.touched.centresId && formik.errors.centresId}
                </small>

                <div className="popup_footer">
                  <button
                    className="btn btnclose"
                    onClick={openModal}
                    type="button"
                  >
                    Close
                  </button>
                  <button type="submit" className="btn btntheme">
                    Save
                  </button>
                </div>
              </form>
            </div>
            {/* <div className="popup_footer">
                <button className="btn btnclose"  onClick={openModal}>Close</button>
                <button className="btn btntheme">Save</button>
            </div> */}
          </div>
        </div>
      )}
    </>
  );
};
